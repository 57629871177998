.banner {
  min-height: 100vh;
  background-image: url(../images/blob.svg);
  background-size: cover;
}
.banner-content {
  padding-top: 100px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 100px;
  align-items: center;
}
.banner-left {
  padding: 50px 0;
}
.banner-left-text {
  font-size: 4rem;
  font-weight: 400;
}
.banner-left-text span {
  background-image: url(../images/splash.svg);
  font-size: 5.5rem;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  padding: 0 20px;
  font-weight: 500;
}
.banner-left p {
  margin: 30px 0;
  line-height: 1.9;
  font-size: 1.25rem;
  color: #444444;
}
.banner-left a {
  display: inline-block;
  padding: 12px 30px;
  font-size: 0.9rem;
  background-color: #00afef;
  color: #ffffff;
  border-radius: 5px;
  text-decoration: none;
}
.banner-right {
  padding: 50px 0;
}
.banner-right img {
  width: 100%;
}
/* Home Focus Section */
.home_focus {
  background-color: #fafafa;
}
.home_focus--grid {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  width: 85%;
  max-width: 1550px;
  margin: 0 auto;
  padding: 50px 0;
}
.home_focus-gridContent {
  text-align: center;
  padding: 50px 0;
  position: relative;
  width: 100%;
}
.home_focus-gridContent img {
  width: 140px;
}
.home_focus-gridContent h3 {
  font-size: 1.8rem;
  font-weight: 500;
  max-width: 50%;
  margin: 0 auto 20px auto;
}
.home_focus-gridContent h3 span {
  color: #00afef;
}
.home_focus_icon {
  font-size: 50px;
  color: #7edcfe;
  position: absolute;
  top: 50%;
  right: -10%;
  transform: translateY(-50%);
}
/* Home-section1 */
.home_section1 {
  padding: 100px 0;
}
.hs1--left h3 {
  font-size: 3.3rem;
  font-weight: 500;
  line-height: 1.4;
}
.hs1--right p {
  margin-bottom: 20px;
  line-height: 1.8;
  font-size: 1.1rem;
  color: #333333;
}
.hs1--right a {
  margin-top: 20px;
}
/* Home-section2 */
.hs2--content {
  padding-top: 20px;
}
.hs2-img {
  position: relative;
}
.hs2-img img {
  width: 100%;
}
/* Home-section3 */
.home_section3,
.home_section9 {
  background-color: #f2f8ff;
  position: relative;
}
.hs3-imgs1 {
  position: absolute;
  top: 0;
  left: 0;
}
.hs3-imgs2 {
  position: absolute;
  bottom: 0;
  right: 0;
}
.hs3--content {
  padding: 100px 0;
}
.hs3--header {
  text-align: center;
  margin-bottom: 70px;
}
.hs3--header h2 {
  color: #121b35;
  font-size: 2.5rem;
}
.hs3--header p {
  font-weight: 500;
  margin-top: 10px;
  color: #121b35;
  font-size: 1rem;
}
.hs3-testi-img {
  margin: 10px 0;
}
.hs3-testi-img img {
  height: 50px;
  width: 50px;
  object-fit: cover;
  position: relative;
  z-index: 1;
  border-radius: 3px;
  border: 1px solid #c8c8c8;
}
.hs3-testi {
  display: flex;
  align-items: center;
  justify-content: center;
}
.hs3-testi-img {
  position: relative;
  display: inline-block;
  margin-right: 10px;
}
.hs3-testi-img::after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  left: -3px;
  top: -3px;
  border: 1px solid #c4c4c4;
  border-radius: 3px;
}
.hs3-testimonial {
  width: 40%;
  margin: 50px auto;
  text-align: center;
}
.hs3-testimonial p {
  font-size: 1.1rem;
  line-height: 1.8;
  color: #444444;
}
/* Homse section4 */
.home_section4 {
  background-color: #f2f8ff;
  padding: 20px 0 80px 0;
}
.hs4 {
  text-align: center;
}
.hs4--top h2,
.h_grid--left h2,
.hgrid--content h2 {
  font-size: 3rem;
  margin-bottom: 30px;
  font-weight: 500;
  max-width: 80%;
}
.hs4--top h2 {
  max-width: 100%;
}
.hs4--top p {
  line-height: 2.3;
  font-size: 1.1rem;
  color: #222222;
  margin-bottom: 30px;
}
/* Home Section5 */
.hs5--grids {
  margin: 50px 0;
  padding: 70px 0;
}
.hs5--grid {
  text-align: center;
}
.hs5--grid h3 {
  font-size: 2.5rem;
  font-weight: 500;
  margin-bottom: 20px;
}
.hs5--grid p {
  line-height: 1.5;
  font-size: 1.1rem;
}
/* Home Section6 */
.hs6--header {
  text-align: center;
  margin-bottom: 100px;
}
.hs6--header h2 {
  font-size: 2.8rem;
  font-weight: 500;
  display: inline-block;
  background-image: url(../images/splash.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}
/* HGrid Section */
.h_grid--left p,
.hgrid--content p {
  line-height: 2.3;
  font-size: 1.1rem;
  color: #222222;
  margin-bottom: 10px;
  max-width: 80%;
}
.h_grid--right img {
  width: 100%;
}
.hgrid--content1 {
  padding: 70px 70px 70px 0;
}
.hgrid--content2 {
  padding: 70px 0 70px 70px;
}
.hgrid--content h2 {
  max-width: 90%;
}
.hgrid--content p {
  max-width: 100%;
}
.hgrid--content p {
  margin-bottom: 50px;
}
.hgrid--content_img img {
  width: 100%;
}
/* Home Section7 */
.home_section7 {
  margin-top: 100px;
}
.hs7--right h2,
.hs7--right p {
  max-width: 100%;
}
/* Home Section8 */
.home_section8 {
  background-color: #ffa41d;
  padding: 50px;
}
.hs8--header {
  margin-bottom: 30px;
  text-align: center;
}
.hs8--header h3 {
  font-size: 2.5rem;
  font-weight: 500;
  margin-bottom: 10px;
}
.hs8--header p {
  font-size: 1.2rem;
}
.hs8--hit-content {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 40px;
}
.hs8--hit {
  display: flex;
  align-items: center;
}
.hs8--hit img {
  width: 3.3rem;
}
.hs8--hit p {
  font-weight: 500;
  font-size: 1.1rem;
  margin-left: 20px;
}
.hs8--aplicationProcess {
  width: 70%;
  margin: 0 auto;
}
/* @media */
@media (max-width: 1300px) {
  .banner-left-text {
    font-size: 3.5rem;
  }
  .home_focus--grid {
    width: 100%;
  }
  .banner-content {
    gap: 50px;
  }
  .home_focus-gridContent img {
    width: 140px;
  }
}
@media (max-width: 1024px) {
  .home_focus-gridContent img {
    width: 110px;
  }
  .home_focus-gridContent h3 {
    font-size: 1.4rem;
    font-weight: 500;
    max-width: 80%;
    margin: 0 auto 20px auto;
  }
}
@media (max-width: 992px) {
  .banner-content {
    grid-template-columns: 1fr;
    padding-top: 70px;
  }
  .banner-right,
  .banner-left {
    padding: 50px 0 0 0;
  }
  .hs1--left h3 {
    font-size: 2.5rem;
  }
  .hgrid--content h2 {
    max-width: 100%;
    font-size: 2.5rem;
  }
  .hs3--content,
  .hgrid--content2 {
    padding: 20px 0;
  }
  .hgrid--content1 {
    padding: 20px 0;
  }
  .hs4--top h2,
  .h_grid--left h2,
  .hgrid--content h2 {
    margin-bottom: 20px;
    max-width: 100%;
    font-size: 2.5rem;
  }
  .hs3-testimonial {
    width: 60%;
  }
  .h_grid--left p,
  .hgrid--content p {
    max-width: 100%;
  }
  .hgrid--content p {
    margin-bottom: 20px;
  }
  .home_section7 {
    margin-top: 50px;
  }
  .hs8--aplicationProcess {
    width: 85%;
  }
}
@media (max-width: 768px) {
  .home_focus--grid {
    flex-direction: column;
  }
  .home_focus_icon {
    display: none;
  }
  .home_focus-gridContent {
    padding: 20px 0;
  }
  .home_section1 {
    padding: 50px 0;
  }
  .hs1--content {
    padding: 40px 0;
  }
  .hs3-testimonial {
    width: 90%;
  }
  .hs5--grids {
    margin: 20px;
    padding: 20px 0;
  }
  .hs6--header {
    text-align: center;
    margin: 50px 0;
  }
  .hs4--top h2,
  .h_grid--left h2,
  .hgrid--content h2 {
    font-size: 2.5rem;
    max-width: 100%;
  }
  .h_grid--left p,
  .hgrid--content p {
    max-width: 100%;
  }
  .home_section7 {
    margin-top: 50px;
  }
  .hs7--right,
  .hs10--right {
    grid-row: 1;
  }
  .hgrid--content2 {
    padding: 50px 0;
  }
  .home_section8 {
    padding: 0;
  }
  .hs8--header h3 {
    font-size: 2.1rem;
  }
  .hs8--hit img {
    width: 2.8rem;
  }
  .hs8--hit p {
    font-size: 0.9rem;
    margin-left: 10px;
  }
  .hs8--aplicationProcess {
    width: 95%;
    padding-top: 30px;
  }
}
@media (max-width: 600px) {
  .home_page {
    min-width: 320px;
  }
  .banner-left-text {
    font-size: 2.5rem;
  }
  .banner-left-text span {
    font-size: 3.5rem;
  }
  .hs1--left h3 {
    font-size: 2rem;
  }
  .hs3--header h2 {
    font-size: 2.1rem;
  }
  .hs4--top h2,
  .h_grid--left h2,
  .hgrid--content h2 {
    font-size: 2.1rem;
  }
  .hs6--header h2 {
    font-size: 2.2rem;
  }
  .home_focus-gridContent img {
    width: 100px;
  }
}
@media (max-width: 425px) {
  .banner-left {
    text-align: center;
  }
  .home_focus-gridContent img {
    width: 80px;
  }
  .banner-left-text {
    text-align: center;
  }
  .banner-left-text span {
    display: block;
  }
}
