.P__banner-content {
  width: 70%;
  margin: 0 auto;
  text-align: center;
  padding: 90px 0;
}
.P__banner-content h2 {
  font-size: 2.6rem;
  font-family: "FredokaOne";
  font-weight: 300;
  margin-bottom: 20px;
}
.P__banner-content a {
  margin-top: 20px;
  display: inline-block;
  text-decoration: none;
  padding: 10px 30px 12px 30px;
  font-size: 0.9rem;
  background-color: #00afef;
  color: white;
  border-radius: 5px;
  border: 1px solid #121b35;
}
/* Pricing Section1 */
.P__section1 {
  padding: 20px 0;
  background-color: #fcfcfc;
}
.P__sect1-grid1 h2 {
  font-size: 1.5rem;
  text-align: center;
  margin: 10px 0;
  font-weight: 600;
}
.p__sect1-btns {
  display: flex;
}
.p__sect1-btn {
  padding: 10px 20px;
  border: none;
  background: none;
  font-size: 0.9rem;
  outline: none;
  cursor: pointer;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
}
.activeCurrency {
  background: #121b35;
  color: white;
}
.p__sect1-grids {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 50px;
  padding-top: 10px;
  align-items: flex-start;
}
.P__sect1-grid {
  border-radius: 10px;
  padding: 10px;
  box-shadow: 2px 2px 10px rgba(192, 192, 192, 0.5);
}
.P__sect1-grid1 {
  background-color: #f7f7f7;
  text-align: center;
}
.P__sect1-grid1-text2 {
  margin-top: 30px;
}
.P__sect1-grid1-text2 h2 {
  color: #ffa41d;
}
.P__sect1-grid1:nth-child(2) {
  background-color: #ffa41d;
  color: white;
}
.P__sect1-grid1:nth-child(2) h2 {
  color: white;
}
.P__sect1-grid1 p {
  font-size: 0.9rem;
}
.P__sect1-grid2 {
  background-color: #121b35;
  color: white;
}
.P__sect1-grid2 h3 {
  text-align: center;
  font-weight: 600;
  font-size: 1.7rem;
}
.P__sect1-grid2-sub {
  display: grid;
  gap: 20px;
  margin-top: 20px;
  font-size: 1rem;
}
.P__sect1-grid2-sub p {
  position: relative;
  padding-left: 30px;
}
.P__sect1-grid2-sub p::after {
  content: "";
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  height: 4px;
  width: 20px;
  background-color: #ffffff;
  position: absolute;
}
.P_sect1-grid-card {
  text-align: left;
  border-top: 1px solid #dedede;
  padding: 10px;
  margin-top: 10px;
}
.P_sect1-flexed {
  display: flex;
  justify-content: space-between;
}
.P_sect1-flexed:not(:nth-last-child(1)) {
  margin-bottom: 20px;
}
.P_sect1-flexed p:first-of-type {
  display: flex;
  align-items: center;
}
.P_sect1-flexed-icon {
  margin-right: 5px;
}
/* Pricing Section 2 */
.PricingSection2 {
  padding: 100px 0;
  text-align: center;
}
.PS2__content {
  width: 70%;
  margin: 0 auto;
}
.PS2__content h2 {
  font-size: 2.8rem;
  margin-bottom: 20px;
  font-weight: 500;
}
.PS2__content p {
  line-height: 1.8;
  font-size: 1.1rem;
  margin-bottom: 20px;
}
.PS2__content-links {
  display: flex;
  gap: 20px;
  justify-content: center;
}
.PS2__content-links a {
  text-decoration: none;
  padding: 10px 30px;
  font-size: 1rem;
  border-radius: 5px;
  margin-top: 20px;
}
.PS2__content-link1 {
  border: 1px solid #00afef;
  color: #00afef;
}
.PS2__content-link2 {
  background-color: #00afef;
  color: white;
}
.PS2__subcontent {
  margin-bottom: 20px;
}
.PS2__subcontent h4 a {
  color: #ffa41d;
  font-size: 18px;
}
/* @media Queries */
@media (max-width: 768px) {
  .P__banner-content {
    width: 90%;
  }
  .P__banner-content h2 {
    font-size: 2rem;
  }
  .p__sect1-grids {
    grid-template-columns: repeat(1, 1fr);
  }
  .PricingSection2 {
    padding: 50px 0;
  }
  .PS2__content {
    width: 90%;
  }
}
@media (max-width: 600px) {
  .pricing_page_container {
    min-width: 320px;
  }
  .P__banner-content {
    width: 95%;
  }
  .PS2__content h2 {
    font-size: 1.8rem;
  }
  .PS2__content-links {
    gap: 10px;
  }
  .PS2__content-links a {
    padding: 10px;
    font-size: 0.8rem;
  }
}
