.CD_banner {
  background-image: url("../images/course-bg.png");
  padding: 100px 0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 500px;
  color: #ffffff;
}
.CD--content h2 {
  color: #ffffff;
  font-size: 4rem;
  max-width: 70%;
  margin-bottom: 30px;
  font-weight: 500;
}
.CD--content p {
  color: #ffffff;
  font-size: 1.1rem;
  max-width: 50%;
  margin-bottom: 20px;
  line-height: 1.9;
}
.CD--content h5 {
  margin-top: 20px;
}
.CD--content h5 span {
  font-weight: 500;
  font-size: 1rem;
}
.CD--content a {
  margin-top: 20px;
}
/* Course Details Section 1 */
.CD_s1--content {
  padding: 20px 0;
}
.CD_s1--grid {
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 50px;
  position: relative;
}
.CD_s1--right-cont {
  position: sticky;
  position: -webkit-sticky;
  top: 91px;
}
.CD_s1--left-list ul {
  list-style: none;
  padding: 20px 10px;
  background-color: #eeeeee;
  display: flex;
  border-radius: 5px;
  position: relative;
  margin-bottom: 30px;
}
.CD_s1--left-list ul::after {
  content: "";
  height: 20px;
  width: 20px;
  position: absolute;
  bottom: -10px;
  left: 3%;
  transform: rotate(45deg);
  background-color: #eeeeee;
}
.CD_s1--left-list ul li {
  cursor: pointer;
  color: #444444;
  font-size: 0.9rem;
  width: 100%;
  font-weight: 500;
}
.CD_s1--left-list ul li a {
  text-decoration: none;
  color: #000000;
}
.CD--overview h2 {
  font-size: 2rem;
  font-weight: 500;
  color: #222222;
  margin-bottom: 10px;
}
.CD--overview-text1 {
  line-height: 2;
  color: #222222;
  font-size: 1rem;
}
.CD--overview-text1:nth-of-type(1) {
  margin-bottom: 20px;
}
.CD--overview h6 {
  margin: 20px 0 10px 0;
  font-size: 0.9rem;
  text-transform: uppercase;
  color: #1b093d;
  text-decoration: underline;
}
.CD--overview h3 {
  font-size: 2.5rem;
  font-weight: 500;
  margin: 10px 0;
}
.CD--overview h5 {
  font-weight: 500;
  margin: 10px 0;
  font-size: 1.1rem;
}
.CD--overview span {
  font-size: 0.8rem;
  color: #555555;
}
/* Course Details Section 1 Right */
.CD_s1--right-video img {
  width: 100%;
}
.CD_s1--right-cont {
  padding: 0 20px 20px 20px;
  border-radius: 5px;
  box-shadow: 2px 2px 10px rgba(203, 203, 203, 0.5);
  margin-top: -40px;
  background-color: #ffffff;
  display: grid;
  grid-template-columns: 1fr;
  gap: 15px;
}
.CD_s1--right-cont-header {
  border-bottom: 1px solid #dedede;
  padding: 10px 0 0 0;
  display: flex;
}
.CD_s1--right-btns {
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  background: none;
  transition: all 0.3s ease;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}
.CD_s1--right-btns:hover {
  background-color: #eeeeee;
}
.CD_s1--right-btns.active {
  background: #1b093d;
  color: white;
}
.CD_s1--right-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 0;
}
.CD_s1--right-flex:not(:last-of-type) {
  border-bottom: 1px solid #eeeeee;
}
.CD_s1--right-flex h4 {
  font-weight: 500;
  margin-left: 5px;
  font-size: 0.9rem;
}
.CD_s1--right-flex p {
  font-size: 0.9rem;
  color: #777777;
}
.CD_s1-price {
  font-size: 2rem !important;
  color: #ffa41d !important;
  font-weight: 700;
}
/* Course Details Section 2 */
.cd_section2 {
  margin-top: 30px;
}
.cd_s2--list {
  margin: 40px 20px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 30px;
}
.cd_s2--list-item {
  align-items: flex-start;
}
.cd_s2--list-item img {
  width: 1.5rem;
}
.cd_s2--list-item p {
  margin-left: 10px;
  font-size: 0.94rem;
}
/* CD Section 4 */
.cd4_tuition--grids {
  margin-top: 40px;
}
.cd4_tution--grid {
  border: 1px solid #1b093d;
  border-radius: 5px;
  padding: 20px;
  position: relative;
  background-color: #ffffff;
}
.cd4_tution--grid::after {
  content: "";
  width: 100%;
  position: absolute;
  z-index: -1;
  height: 100%;
  left: -10px;
  top: -10px;
  background-color: #01aeef;
  border-radius: 5px;
  border: 1px solid #1b093d;
}
.cd4_tution--grid h4 {
  margin-bottom: 20px;
  font-weight: 500;
  font-size: 1.3rem;
}
.cd4_tution--grid h2 {
  font-size: 2.3rem;
  line-height: 1.2;
}
.cd4_tution--grid span {
  display: block;
  font-size: 0.8rem;
  margin-bottom: 20px;
}
.cd4_tution--grid p {
  line-height: 2;
  color: #444444;
  font-size: 0.9rem;
}
/* @media */
@media (max-width: 1300px) {
  .CD_s1--left-list ul {
    justify-content: space-between;
  }
  .CD_s1--left-list ul li {
    width: auto;
  }
}
@media (max-width: 992px) {
  .CD_banner {
    padding: 50px 0;
    min-height: auto;
  }
  .CD--content h2 {
    font-size: 3rem;
    max-width: 100%;
    text-align: center;
  }
  .CD--content p {
    font-size: 1rem;
    max-width: 100%;
  }
  .CD_s1--grid {
    grid-template-columns: 1fr;
  }
  /* .CD_s1--right {
    grid-row: 1;
  } */
}
@media (max-width: 768px) {
  .CD_s1--left-list ul li {
    font-size: 0.8rem;
  }
  .CD_s1-price {
    font-size: 1.5rem !important;
  }
  .cd_s2--list {
    margin: 20px 0;
    gap: 20px;
  }
  .CD--content h2 {
    font-size: 2.5rem;
    text-align: left;
    margin-bottom: 20px;
  }
}
@media (max-width: 600px) {
  .CD_s1--left-list ul {
    flex-direction: column;
    gap: 30px;
  }
  .CD--overview h2 {
    font-size: 1.8rem;
  }
  .hs8--header h3 {
    font-size: 1.8rem;
  }
  .hs8--header p {
    font-size: 1rem;
  }
  .CD--content h2 {
    font-size: 2.2rem;
  }
  .cd_s2--list2 {
    grid-template-columns: repeat(1, 1fr);
  }
}
@media (max-width: 410px) {
  .course_details {
    min-width: 320px;
  }
}
