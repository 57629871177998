@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;500;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800&display=swap");
@font-face {
  font-family: FredokaOne;
  src: url("./assets/styles/FredokaOne-Regular.ttf");
}
*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  margin: 0;
  /* font-family: "Roboto", sans-serif; */
  font-family: "Poppins", sans-serif;
  font-family: "Montserrat", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
  /* scroll-behavior: smooth; */
}
.space-top {
  padding-top: 100px;
}
.container {
  width: 85%;
  margin: 0 auto;
  max-width: 1500px;
}
.btns-primary {
  display: inline-block;
  padding: 12px 30px;
  font-size: 0.9rem;
  background-color: #00afef;
  color: #ffffff;
  border-radius: 5px;
  text-decoration: none;
  text-decoration: none;
}
.btns-orange {
  display: inline-block;
  padding: 12px 30px;
  font-size: 0.9rem;
  background-color: #ffa41d;
  color: #ffffff;
  border-radius: 5px;
  text-decoration: none;
  text-decoration: none;
}
.btns-outline-primary {
  display: inline-block;
  padding: 12px 30px;
  font-size: 0.9rem;
  background: none;
  color: #00afef;
  border-radius: 5px;
  text-decoration: none;
  border: 1px solid #00afef;
  transition: all 0.3s ease;
}
.btns-outline-primary:hover {
  background-color: #00afef;
  color: white;
}
.grids {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}
.grid1 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 100px;
}
.grid2 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 100px;
}
.flexed1 {
  display: flex;
  align-items: center;
}
/* Application Process */
.ApplicationProcess {
  padding: 10px 0;
}
.AP_accordion {
  margin-bottom: 20px;
  padding: 10px;
  background-color: #efefef;
  border-radius: 5px;
}
.AP_accordion.AP_active,
.AP_accordion.AP_active button {
  background-color: #fefefe;
  color: #222222;
}
.AP__accordion-btn {
  display: flex;
  width: 100%;
  padding: 10px 0;
  border: none;
  font-size: 1.1rem;
  justify-content: space-between;
  align-items: center;
  background: none;
  cursor: pointer;
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
}
.AP_btn-active {
  border-bottom: 1px solid #dedede;
}
.AP__accordion-text {
  height: 0px;
  overflow: hidden;
  transition: all 0.5s ease;
  line-height: 1.9;
  font-size: 1rem;
}
.AP__accordion-text.accordion-text-active {
  height: auto;
  overflow: auto;
  padding: 10px 0;
}
.AP__Acc-icon {
  transition: all 0.3s ease;
}
.AP__Acc-icon.acc-icon-active {
  transform: rotate(180deg);
}
/* @media */
@media (max-width: 1300px) {
  .container {
    width: 95%;
  }
  .grid1,
  .grid2 {
    gap: 40px;
  }
}
@media (max-width: 992px) {
  .grids {
    gap: 20px;
  }
}
@media (max-width: 768px) {
  .grid1,
  .grid2,
  .grids {
    grid-template-columns: 1fr;
  }
  .space-top {
    padding-top: 50px;
  }
}
@media (max-width: 600px) {
  .AP__accordion-btn {
    font-size: 0.9rem;
    align-items: flex-start;
  }
  .AP_accordion-btn-text {
    width: 90%;
    text-align: left;
  }
}
