.aboutBanner {
  padding: 50px;
}
.aboutBanner {
  width: 90%;
  margin: 20px auto;
  text-align: center;
}
.aboutBanner h2 {
  font-size: 2.5rem;
  font-weight: 300;
  font-family: "FredokaOne";
  line-height: 1.5;
}
.aboutBanner p {
  margin-top: 20px;
  font-size: 18px;
}
.flex-all {
  display: flex;
  justify-content: space-between;
}
/* About Section 1 */
.about_section {
  padding: 20px;
  background-color: #fafafa;
}
.abount__sect1-grids {
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(2, 1fr);
  margin-top: 50px;
}
.a__sect1 h4 {
  font-size: 1.2rem;
}
.a__sect1 h2 {
  margin: 20px 0;
  font-size: 2.6rem;
  font-weight: 600;
}
.a__sect11 {
  display: grid;
  gap: 20px;
}
.a__sect11 p {
  font-size: 1.05rem;
  line-height: 1.9;
}
.a__sect1-img img {
  width: 100%;
}
/* About Section 1 */
.a__sect2 {
  width: 80%;
  margin: 0 auto;
  text-align: center;
}
.a__sect2 h2 {
  font-size: 3rem;
  line-height: 1.3;
  margin: 30px 0;
  font-family: "FredokaOne";
  font-weight: 300;
}
.a__sect2 p {
  font-size: 17px;
  margin-top: 20px;
  line-height: 1.7;
  font-weight: 500;
}
/* Why Choose US */
.why-choose-us {
  padding: 150px 0;
  background-color: #fafafa;
  margin-top: 80px;
}
.wcu__contents {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  text-align: center;
}
.wcu__content h4 {
  font-size: 22px;
  margin-top: 20px;
}
.wcu__content img {
  display: flex;
  margin: 0 auto;
  height: 100px;
}

/* @media */
@media (max-width: 1024px) {
  .aboutBanner h2 {
    font-size: 40px;
  }
}
@media (max-width: 768px) {
  .about_section {
    padding: 10px;
  }
  .aboutBanner {
    width: 95%;
  }
  .aboutBanner {
    padding: 30px 0;
  }
  .aboutBanner h2 {
    font-size: 1.8rem;
  }
  .abount__sect1-grids {
    grid-template-columns: repeat(1, 1fr);
  }
  .a__sect2 {
    width: 95%;
  }
  .a__sect2 h2 {
    font-size: 2.3rem;
  }
  .wcu__contents {
    grid-template-columns: repeat(1, 1fr);
    gap: 40px;
  }
  .why-choose-us {
    padding: 30px 0;
  }
}
@media (max-width: 600px) {
  .aboutBanner h2,
  .a__sect2 h2 {
    font-size: 1.5rem;
  }
  .a__sect1 h4,
  .a__sect11 p {
    font-size: 1rem;
  }
  .a__sect1 h2 {
    margin: 10px 0;
    font-size: 1.8rem;
  }
  .wcu__content h4 {
    font-size: 1.1rem;
    margin-top: 20px;
  }
}
@media (max-width: 425px) {
  .aboout_page_container {
    min-width: 320px;
  }
  .wcu__content img {
    height: 80px;
  }
}
