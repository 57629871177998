.error_page {
  min-height: 100svh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.error_content {
  text-align: center;
}
.error_content h3 {
  font-size: 90px;
}
.error_content a {
  margin: 10px 0;
  text-decoration: none;
  padding: 10px 30px;
  font-size: 14px;
  border: 1px solid #00afef;
  color: #00afef;
  border-radius: 5px;
  display: inline-block;
  transition: all 0.3s ease;
}
.error_content a:hover {
  background-color: #00afef;
  color: #ffffff;
}
